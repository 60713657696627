import {DirectUpload} from "@rails/activestorage"
import Dropzone from "dropzone"
import {findElement, getMetaValue, insertAfter, removeElement} from "@/helpers"
import FormUtils from '../helpers/form-utils';

import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', "blDoc", "commercialInvoiceDoc", "freightInvoiceDoc"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  transferFileFromDropzoneToInput(file, target) {
    let data_transfer = new DataTransfer();
    data_transfer.items.add(file);
    target.files = data_transfer.files;

    const event = new Event('change', { bubbles: true });
    target.dispatchEvent(event);
  }

  hideLoader({ loaderId, textContainerId }) {
    const loaderElement = document.getElementById(loaderId);
    const textContainerElement = document.getElementById(textContainerId);

    loaderElement?.classList.remove("success");
    loaderElement?.classList.add("hidden");
    textContainerElement?.classList.add("hidden");
  }

  bindEvents() {
    this.dropZone.on('addedfile', file => {
      this.inputTarget.dataset.fileCount = this.dropZone.files.length;
      this.inputTarget.dispatchEvent(FormUtils.fileCountChangeEvent);

      if (this.dropZone.files.length === Number(this.maxFiles)) {
        this.dropZone.element.style.pointerEvents = 'none';
      }
      
      const dropzoneTarget = this.inputTarget.attributes["data-dropzone-target"].value;
      if (dropzoneTarget === "input blDoc") {
        this.transferFileFromDropzoneToInput(file, this.blDocTarget);
      } else if (dropzoneTarget === "input commercialInvoiceDoc") {
        this.transferFileFromDropzoneToInput(file, this.commercialInvoiceDocTarget);
      } else if (dropzoneTarget === "input freightInvoiceDoc") {
        this.transferFileFromDropzoneToInput(file, this.freightInvoiceDocTarget);
      }

      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on('removedfile', file => {
      this.inputTarget.dataset.fileCount = this.dropZone.files.length;
      this.inputTarget.dispatchEvent(FormUtils.fileCountChangeEvent);

      if (this.dropZone.files.length < Number(this.maxFiles)) {
        this.dropZone.element.style.pointerEvents = 'all';
      }

      file.controller && removeElement(file.controller.hiddenInput);

      const dropzoneTarget = this.inputTarget.getAttribute("data-dropzone-target");
      const loaders = {
        "input blDoc": {
          loaderId: "blDoc-loader",
          textContainerId: "blDocTextContainer",
        },
        "input commercialInvoiceDoc": {
          loaderId: "commercialInvoiceDoc-loader",
          textContainerId: "commercialInvoiceDocTextContainer",
        },
        "input freightInvoiceDoc": {
          loaderId: "freightInvoiceDoc-loader",
          textContainerId: "freightInvoiceDocTextContainer",
        }
      };

      if (loaders[dropzoneTarget]) {
        this.hideLoader(loaders[dropzoneTarget]);
      }
    });

    this.dropZone.on('canceled', file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1;
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256;
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles');
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener('progress', event =>
      this.uploadRequestDidProgress(event),
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      '.dz-upload',
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
  });
}
