import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

const buttonLoadingContent = `<div class="button-loading"><span class="button-loading__title">Document processing...</span><span class="button-loading__description">Once the process is complete, the form will be filled in automatically.</span></div>`;

const buttonParsedContent = `<div class="button-parsed"><span class="button-parsed__title">Process finished!</span><span class="button-parsed__description">Form filled. Please check the values.</span></div>`;

const autoAwesomeIcon = `<svg height="20" fill="#00897b" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z"/></svg>`;

const contentCopyIcon = `<svg height="14" fill="#00897b" viewBox="0 0 24 24" width="14"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>`;

const checkCircleIcon = `<svg height="14" viewBox="0 0 24 24" width="14" fill="#00897b"><path d="m0 0h24v24h-24z" fill="none"/><path d="m12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71-5.88 5.88-1.88-1.88c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l2.59 2.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41s-1.03-.39-1.42 0z"/></svg>`

const BL_JOB_ID = "palantir_bl_job_id";
const COM_INVOICE_JOB_ID = "palantir_com_invoice_job_id";

function poll(fn, timeout = 90000, interval = 2000) {
    const endTime = Number(new Date()) + timeout;

    async function checkCondition(resolve, reject) {
        try {
            const result = await fn();
            if (result) {
                resolve(result);
            } else if (Number(new Date()) < endTime) {
                setTimeout(checkCondition, interval, resolve, reject);
            } else {
                reject(new Error("request timed out"));
            }
        } catch (error) {
            reject(error);
        }
    }
    return new Promise(checkCondition);
}

export default class PalantirController extends Controller {
    static targets = [
        "blDoc",
        "blDocText",
        "commercialInvoiceDoc",
        "commercialInvoiceDocText",
        "freightInvoiceDoc",
        "freightInvoiceDocText",
    ];

    static values = {
        apikey: String,
        clientid: String,
        apiurl: String,
        dataBL: Object,
        dataComInvoice: Object,
        countryName: String,
    };
    connect() {
        console.log("Palantir Connected!")
        this.palantirBLJobId = this.getPalantirBLJobId();
        this.palantirComInvoiceJobId = this.getPalantirComInvoiceJobId();
        this.appendJobIdToForm(BL_JOB_ID, this.palantirBLJobId);
        this.appendJobIdToForm(COM_INVOICE_JOB_ID, this.palantirComInvoiceJobId);
        const path = location.pathname;
        this.isNewPath = path.includes("/entries/new");
    }

    formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    onFilesChange(event) {
        const attachment = event.params.attachment;

        const loaders = {
            blDoc: {
                loaderId: "blDoc-loader",
                textContainerId: "blDocTextContainer",
                textTarget: this.blDocTextTarget,
                type: "bl",
            },
            commercialInvoiceDoc: {
                loaderId: "commercialInvoiceDoc-loader",
                textContainerId: "commercialInvoiceDocTextContainer",
                textTarget: this.commercialInvoiceDocTextTarget,
                type: "commercial-invoice",
            },
            // freightInvoiceDoc: {
            //  loaderId: "freightInvoiceDoc-loader",
            //  textContainerId: "freightInvoiceDocTextContainer",
            //  textTarget: this.freightInvoiceDocTextTarget,
            //  type: "freight-invoice",
            //  },
        };

        if (loaders[attachment] && this.isNewPath) {
            this.showLoader(loaders[attachment], event.target);
        }
    }

    getPalantirBLJobId() {
        return this.isNewPath ? null : sessionStorage.getItem(BL_JOB_ID);
    }

    getPalantirComInvoiceJobId() {
        return this.isNewPath ? null : sessionStorage.getItem(COM_INVOICE_JOB_ID);
    }

    appendJobIdToForm(key, jobId = this.getPalantirBLJobId()) {
        window.sessionStorage.setItem(key, jobId);
        if (!jobId) return;

        const form = document.querySelector(
            `form#new_entry`,
        );
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = jobId;
        form?.appendChild(input);
    }

    async submitDocument(fileInput, type = "bl") {
        const formData = new FormData();
        formData.append("file", fileInput.files[0]);
        formData.append("country", "djibouti");
        let key = BL_JOB_ID;
        if (type === "bl") {
            key = BL_JOB_ID;
        } else if (type === "commercial-invoice") {
            key = COM_INVOICE_JOB_ID;
        }
        return fetch(`${this.apiurlValue}/api/parse/${type}`, {
            method: "POST",
            headers: {
                "X-API-KEY": this.apikeyValue,
                "X-CLIENT-ID": this.clientidValue,
            },
            body: formData,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`API Error`);
                } else return response.json();
            })
            .then(async ({ data, jobId, status }) => {
                this.appendJobIdToForm(key, jobId);
                if (!data && status?.toLowerCase() === "in_progress") {
                    const jobStatus = await poll(async () => {
                        return fetch(`${this.apiurlValue}/api/jobs/${jobId}/status`, {
                            method: "GET",
                            headers: {
                                "X-API-KEY": this.apikeyValue,
                                "X-CLIENT-ID": this.clientidValue,
                            },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.status?.toLowerCase() === "completed" && data.data) {
                                    return data;
                                } else {
                                    return null;
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                                return null;
                            });
                    });
                    if (type === "bl") {
                        this.dataBLValue = jobStatus.data;
                        this.fillTheForm();
                    } else if (type === "commercial-invoice") {
                        this.dataComInvoiceValue = jobStatus.data;
                        this.fillInvoiceDetails();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                throw new Error(`An error occurred while processing the file.`);
            });
    }

    showLoader({ loaderId, textContainerId, textTarget, type, cb }, fileInput) {
        const loaderElement = document.getElementById(loaderId);
        const textContainerElement = document.getElementById(textContainerId);

        textContainerElement.classList.remove("hidden");
        loaderElement.classList.remove("hidden");
        textTarget.innerHTML = buttonLoadingContent;
        this.submitDocument(fileInput, type)
            .then(() => {
                loaderElement.classList.add("success");
                textTarget.innerHTML = buttonParsedContent;
            })
            .catch((error) => {
                loaderElement.classList.add("error");
                loaderElement.classList.remove("loader");
                textTarget.innerHTML = `
            <span style="font-weight: 600; color: #6b7280;">
              ${error.message}
            </span>
            <br/>
            <span style="color: #6b7280;">
              You can safely ignore this error and continue filling the form manually.
            </span>`;
            });
    }

    makePopupContent(value) {
        const wrapper = document.createElement("div");
        const icon = document.createElement("div");
        icon.style.userSelect = "none";
        icon.innerHTML = contentCopyIcon;
        wrapper.textContent = value;
        wrapper.appendChild(icon);
        return [wrapper, icon];
    }

    makeAiIcon() {
        const wrapper = document.createElement("div");
        wrapper.style.position = "absolute";
        wrapper.style.right = "0";
        wrapper.style.top = "0";
        wrapper.style.margin = "auto";
        wrapper.setAttribute("palantir-type", "tippy-wrapper");
        const aiIcon = document.createElement("div");
        aiIcon.innerHTML = autoAwesomeIcon;
        wrapper.appendChild(aiIcon);
        return { wrapper, aiIcon };
    }

    manageInput(selector, data, parent = null) {
        const { wrapper, aiIcon } = this.makeAiIcon();

        const target = (parent || document).querySelector(selector);
        if (!target || !data) {
            return;
        }

        const value =
            typeof data === "object"
                ? "sanitized" in data
                    ? data.sanitized
                    : data.value
                : data;

        target.value = value ?? data.default ?? "";

        if (value === undefined || value === null) {
            target.style.border = "1px solid red";
            return;
        }

        if (target.classList.contains("select2-hidden-accessible")) {
            target.value = value;
            const event = new Event("change", { bubbles: true });
            target.dispatchEvent(event);

            setTimeout(() => {
                const options = Array.from(target.options);
                const option = options.find(opt => opt.text.toLowerCase() === value.toLowerCase());
                if (option) {
                    target.value = option.value;
                    target.dispatchEvent(event);
                }
            }, 2000);
        }

        if (
            (target.tagName === "SELECT" || target.nodeName === "SELECT") &&
            !target.classList.contains("select2-hidden-accessible")
        ) {
            // Regular select. Search among the labels and set the value
            const options = target.querySelectorAll("option");
            options.forEach((option) => {
                if (option.text.toLowerCase() === value.toLowerCase()) {
                    option.selected = true;
                }
            });
        }

        if (target.getAttribute("data-datepickr-target")) {
            const parts = value.split("-");
            const formattedDate = new Date(parts[2], parts[1] - 1, parts[0]);
            flatpickr(target, {
                dateFormat: "d-m-Y",
                defaultDate: typeof value === "string" ? new Date(formattedDate) : value,
            });
        }

        if (target.getAttribute("data-currency-target")) {
            target.value = value;
        
            target.focus();
        
            target.dispatchEvent(new KeyboardEvent("keydown", { key: "Tab", code: "Tab", keyCode: 9, bubbles: true }));
            target.dispatchEvent(new KeyboardEvent("keyup", { key: "Tab", code: "Tab", keyCode: 9, bubbles: true }));

            target.dispatchEvent(new Event("change", { bubbles: true }));
        }

        // Add AI Icon next to the label
        const label = (
            target.classList.contains("select2-hidden-accessible")
                ? target.parentNode.parentNode
                : target.parentNode
        ).querySelector(target.classList.contains("select2-hidden-accessible") ? "legend" : "label");
        label?.querySelector("[palantir-type=tippy-wrapper]")?.remove();
        label?.appendChild(wrapper);

        // Create a popup content for the AI Icon
        const [popupContent, icon] = this.makePopupContent(value);
        icon.addEventListener("click", (e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(value);
            const parent = e.target.parentElement;
            parent.innerHTML = checkCircleIcon;
            setTimeout(() => {
                parent.innerHTML = contentCopyIcon;
            }, 1000);
        });

        tippy(aiIcon, {
            content: popupContent,
            trigger: "click",
            allowHTML: true,
            interactive: true,
            inertia: true,
        });

        const borderTarget = target.classList.contains("select2-hidden-accessible")
            ? target.parentNode.querySelector(".select2-selection.select2-selection--single")
            : target;
        // Add border color based on confidence
        if (data.confidence === 1.0) {
            borderTarget.style.border = "1px solid #22c55e";
        } else if (data.confidence < 1.0 && data.confidence >= 0.75) {
            borderTarget.style.border = "1px solid #84cc16";
        } else if (data.confidence < 0.75 && data.confidence >= 0.5) {
            borderTarget.style.border = "1px solid #eab308";
        } else if (data.confidence < 0.5 && data.confidence > 0.1) {
            borderTarget.style.border = "1px solid #a16207";
        } else {
            borderTarget.style.border = "1px solid #ef4444";
        }

        target.addEventListener("input", function () {
            borderTarget.style.border = "1px solid #C3C3C3";
        });
    }

    fillTheForm() {
        this.fillBlNumber();
        this.fillShipperSection();
        this.fillConsigneeSection();
        this.fillNotifySection();
        this.fillBLSection();
        this.fillShipmentDetails();
    }

    fillShipperSection() {
        this.manageInput(
            `#entry_shipper_attributes_name`,
            this.dataBLValue.shipper_name,
        );
        this.manageInput(`#entry_shipper_attributes_phone`, {
            ...this.dataBLValue.shipper_phone,
            default: "0",
        });
        this.manageInput(
            `#entry_shipper_attributes_address`,
            this.dataBLValue.shipper_address,
        );
    }

    fillConsigneeSection() {
        this.manageInput(
            `#entry_consignee_attributes_name`,
            this.dataBLValue.consignee_name,
        );
        this.manageInput(`#entry_consignee_attributes_phone`, {
            ...this.dataBLValue.consignee_phone,
            default: "0",
        });
        this.manageInput(
            `#entry_consignee_attributes_address`,
            this.dataBLValue.consignee_address,
        );
    }

    fillNotifySection() {
        this.manageInput(
            `#entry_notify_attributes_name`,
            this.dataBLValue.notify_name,
        );
        this.manageInput(`#entry_notify_attributes_phone`, {
            ...this.dataBLValue.notify_phone,
            default: "0",
        });
        this.manageInput(
            `#entry_notify_attributes_address`,
            this.dataBLValue.notify_address,
        );
    }

    fillBlNumber() {
        this.manageInput(
            `#entry_bl_number`,
            this.dataBLValue.bill_of_lading_number,
        );
    }

    fillBLSection() {
        this.manageInput(
            `#entry_voyage_no`,
            this.dataBLValue.voyage_number,
        );
        this.manageInput(
            "#entry_country",
            this.dataBLValue.country_of_loading,
        );
        setTimeout(() => {
            this.manageInput(
                `#entry_port_of_loading_id`,
                this.dataBLValue.port_of_loading,
            );
        }, 2000);
        this.manageInput(
            `#entry_port_of_discharge_id`,
            this.dataBLValue.port_of_discharge,
        );
        this.manageInput(
            `#entry_vessel_id`,
            this.dataBLValue.vessel,
        );
        this.manageInput(`#entry_etd`,
            this.dataBLValue.shipped_on_board_date || this.formatDate(new Date(
                new Date().setMonth(new Date().getMonth() + 1),
            ).toISOString())
        );
        this.manageInput(
            `#entry_carrier_id`,
            this.dataBLValue.shipping_line,
        );
    }

    fillShipmentDetails() {
        this.manageInput(`#entry_volume`, {
            ...this.dataBLValue.total_measurement,
            default: 0,
        });
        this.manageInput(
            `#entry_description_of_goods`,
            this.dataBLValue.description_of_goods ||
            this.dataBLValue.containers?.[0]?.description_of_goods,
        );

        const shipmentType = this.predictShipmentType(this.dataBLValue);
        this.manageInput("#entry_shipment_type", {
            value: shipmentType,
            confidence: 1.0,
        });

        this.manageInput(
            `.entry_goods_quantity input`,
            this.dataBLValue.number_of_packages,
        );

        this.manageInput(
            `#entry_total_gross_weight`,
            this.dataBLValue.total_weight,
        );

        setTimeout(() => {
            const addBtn = document.querySelector(
                "[data-palantir-type='add-row'][data-assoctype='containers']:not([data-assoctype='goods'])"
            );
            if (!addBtn) return;

            if (shipmentType === "container") {
                for (let i = 0; i < this.dataBLValue.containers.length; i++) {
                    addBtn.click();
                }
                setTimeout(() => {
                    document.querySelectorAll('[data-palantir-type="container-row"]')
                        .forEach((box, i) => {
                            this.manageInput(
                                '[data-palantir-type="container_size"]',
                                this.dataBLValue.containers[i].container_size,
                                box,
                            );
                            this.manageInput(
                                '[data-palantir-type="container_type"]',
                                this.dataBLValue.containers[i].container_type,
                                box,
                            );
                            this.manageInput(
                                '[data-palantir-type="container_number"]',
                                this.dataBLValue.containers[i].container_no,
                                box,
                            );
                            this.manageInput(
                                '[data-palantir-type="seal_number"]',
                                this.dataBLValue.containers[i].seal_no,
                                box,
                            );
                        });
                }, 2000);
            } else if (shipmentType === "roro") {
                for (let i = 0; i < this.dataBLValue.roro?.length; i++) {
                    addBtn.click();
                }
            } else {
                addBtn.click();
            }
        }, 500);
    }

    predictShipmentType(data) {
        if (data.containers?.length > 0) return "container";
        if (data.roro?.length > 0) return "roro";
        if (data.genco) return "genco";
        if (data.bulk) return "bulk";
        return "unknown";
    }

    fillInvoiceDetails() {
        this.manageInput(
            `#entry_incoterm`,
            this.dataComInvoiceValue.incoterm,
        );
        this.manageInput(
            `#entry_currency_of_goods`,
            {
                value: this.dataComInvoiceValue.currency_of_goods,
                confidence: 1.0,
            }
        );
        this.manageInput(
            `#entry_currency_of_insurance`,
            {
                value: this.dataComInvoiceValue.currency_of_insurance,
                confidence: 1.0,
            }
        );
        this.manageInput(
            `#entry_currency_of_freight`,
            {
                value: this.dataComInvoiceValue.currency_of_freight,
                confidence: 1.0,
            }
        );
        this.manageInput(
            `#entry_currency_of_other_charges`,
            {
                value: this.dataComInvoiceValue.currency_of_other_charges,
                confidence: 1.0,
            }
        );
        this.manageInput(
            `#entry_insurance_value`,
            {
                value: this.dataComInvoiceValue.insurance_value || 0,
                confidence: 0.7,
            }
        );
        this.manageInput(
            `#entry_cost_of_goods`,
            {
                value: this.dataComInvoiceValue.fob_value,
                confidence: 0.7,
            }
        );
        this.manageInput(
            `#entry_freight_value`,
            {
                value: this.dataComInvoiceValue.ocean_freight || 0,
                confidence: 0.7,
            }
        );
    }
}